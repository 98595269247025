@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Tajawal:wght@200;500&family=Vollkorn:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Tajawal:wght@200;500&family=Vollkorn:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Tajawal:wght@200;500&family=Vollkorn:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Tajawal:wght@200;500&family=Vollkorn:wght@200;500&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

.App{font-family:"Vollkorn",serif;font-weight:lighter}.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#EEE7ED;min-height:100vh;display:flex;color:#C03221}.App-link{color:#634870}strong{font-family:"Vollkorn",serif;font-weight:bold}

.Welcome_Welcome__3EPr1{display:flex;flex-direction:column;justify-content:center;align-items:center;color:#110213;width:100%}.Welcome_stack__2QndR{position:relative;width:100%;height:100%}

.welcome-exit{color:#110213}.welcome-exit-active{color:transparent;transition:color .5s linear}

.InputBox_InputBox__2BhL6{display:inline-flex;justify-content:center;align-items:center}.InputBox_Input__IIzjT{position:absolute;text-align:center;width:auto;background-color:transparent;border:0px}.InputBox_Input__IIzjT:focus-visible{outline:none}.InputBox_inputBorder__XCROo{position:absolute;width:clamp(200px, 50vw, 1200px);height:clamp(80px, 10vw, 120px);border:clamp(1px, 2vw, 3px) #110213 solid;border-radius:25px}.InputBox_fadeIn__WdxUr{animation:InputBox_fadeIn__WdxUr 1s;-webkit-animation:InputBox_fadeIn__WdxUr 1s;-moz-animation:InputBox_fadeIn__WdxUr 1s}.InputBox_fadeOut__2lYLM{animation:InputBox_fadeOut__2lYLM 1s;-webkit-animation:InputBox_fadeOut__2lYLM 1s;-moz-animation:InputBox_fadeOut__2lYLM 1s;border:clamp(1px, 2vw, 3px) transparent solid}@keyframes InputBox_fadeIn__WdxUr{0%{border-color:transparent}100%{border-color:#110213}}@-webkit-keyframes InputBox_fadeIn__WdxUr{0%{border-color:transparent}100%{border-color:#110213}}@keyframes InputBox_fadeOut__2lYLM{0%{border-color:#110213}100%{border-color:transparent}}@-webkit-keyframes InputBox_fadeOut__2lYLM{0%{border-color:#110213}100%{border-color:transparent}}

.MsgBox_MsgBox__3bVf1{display:flex;height:100px;justify-content:center}.MsgBox_message__2qY30{font-size:32px}

.msg-appear{-webkit-transform:translate(0px, -100px);transform:translate(0px, -100px);color:transparent}.msg-appear-active{-webkit-transform:translate(0px, 0px);transform:translate(0px, 0px);color:#110213;transition:color 0.5s cubic-bezier(1, 0, 0.5, 1),-webkit-transform 0.5s cubic-bezier(0, 0, 0.35, 1);transition:transform 0.5s cubic-bezier(0, 0, 0.35, 1),color 0.5s cubic-bezier(1, 0, 0.5, 1);transition:transform 0.5s cubic-bezier(0, 0, 0.35, 1),color 0.5s cubic-bezier(1, 0, 0.5, 1),-webkit-transform 0.5s cubic-bezier(0, 0, 0.35, 1)}.msg-enter{-webkit-transform:translate(0px, -100px);transform:translate(0px, -100px);color:transparent}.msg-enter-active{-webkit-transform:translate(0px, 0px);transform:translate(0px, 0px);color:#110213;transition:color 0.5s cubic-bezier(1, 0, 0.5, 1),-webkit-transform 0.5s cubic-bezier(0, 0, 0.35, 1);transition:transform 0.5s cubic-bezier(0, 0, 0.35, 1),color 0.5s cubic-bezier(1, 0, 0.5, 1);transition:transform 0.5s cubic-bezier(0, 0, 0.35, 1),color 0.5s cubic-bezier(1, 0, 0.5, 1),-webkit-transform 0.5s cubic-bezier(0, 0, 0.35, 1)}.msg-exit{color:#110213}.msg-exit-active{color:transparent;transition:color .25s}

.MainPage_MainPage__2bW37{display:flex;width:100%;font-family:"Vollkorn",serif;color:#285346;animation-name:MainPage_fadeIn__2ttG4;animation-duration:.5s;-webkit-animation-name:MainPage_fadeIn__2ttG4;-webkit-animation-duration:.5s;-moz-animation-name:MainPage_fadeIn__2ttG4;-moz-animation-duration:.5s;padding-left:200px}.MainPage_MainPage__2bW37 h1{font-family:"Bungee Shade",cursive}.MainPage_MainPage__2bW37 a{color:#634870}@keyframes MainPage_fadeIn__2ttG4{0%{opacity:0}100%{opacity:1}}@-webkit-keyframes MainPage_fadeIn__2ttG4{0%{opacity:0}100%{opacity:1}}.MainPage_sidenav__1b-Wa{display:flex;flex-direction:column;align-items:left;justify-content:center;height:100%;position:fixed;z-index:1;top:0;left:0}.MainPage_sidenav__1b-Wa button{font:inherit;background:none;border:none;padding:8px 8px 8px 32px;text-decoration:none;font-size:25px;color:#285346;display:block;transition:0.2s}.MainPage_sidenav__1b-Wa button:hover{color:#634870;text-decoration:underline}@media screen and (max-width: 600px){.MainPage_MainPage__2bW37{padding-left:0}.MainPage_sidenav__1b-Wa{flex-direction:row;justify-content:space-evenly;padding:10px 0px 0px 0px;height:auto;width:100%;margin-left:0}.MainPage_sidenav__1b-Wa button{font-size:18px;padding:0}}.MainPage_contentContainer__UkfUD{display:flex;justify-content:center;align-items:center;width:inherit}

.AboutMe_AboutMe__216LD{display:flex;flex-direction:column;align-items:center}.AboutMe_content__3pjWD{width:40vw}img{width:40vw;max-width:600px}



.Experience_Experience__201eU{width:70vw}

