@import '../../colors';
@import '../../fonts';

.Welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $main;
  width: 100%;
}

.stack {
  position:relative;
  width: 100%;
  height: 100%;
}

