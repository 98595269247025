@import 'colors';
@import 'fonts';

.App {
  font-family: $serif;
  font-weight: lighter;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $background;
  min-height: 100vh;
  display: flex;
  color: $danger;
}

.App-link {
  color: $neutral;
}

strong{
  font-family: $serif;
  font-weight: bold;
}