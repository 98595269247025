@import '../../colors';

.MsgBox {
  display: flex;
  height: 100px;
  justify-content: center;
}

.message {
  font-size: 32px;
}

